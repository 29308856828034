import React, { createContext, useContext } from 'react';
 
 
 
 

const ImageContext = createContext();

export function ImageProvider({ children }) {
  const images = {
 
   
 
    
    // Adicione mais imagens conforme necessário
  };

  return (
    <ImageContext.Provider value={images}>{children}</ImageContext.Provider>
  );
}

export function useImages() {
  return useContext(ImageContext);
}
