import React from 'react';
import { Container } from '.././styles';
 

const Blog1 = () => {
  return (
    <Container>
      <h2>ss</h2>
      <p>Esta é a nova página que você criou!</p>
 
    </Container>
  );
}

export default Blog1;
