import React from 'react';
import { Mapastyle } from './style';
import { Link } from 'react-router-dom';

import { data } from './../dataTextos';

export default function ContatoEMapa() {
    return (
        <Mapastyle>
            <h2>Entre em contato para marcar uma orçamento </h2>
            <p>Estamos ansiosos para ajudá-lo a impulsionar o sucesso do seu negócio. Entre em contato conosco para mais informações e para agendar uma consulta personalizada.</p>
            <div className="contact-options">
                <div className="option">
                    <div className="icon">📧</div>
                    <div className="info">
                        <p>E-mail:</p>
                        <a href={`mailto:${data.footer.contatos.email}`}>{data.footer.contatos.email}</a>
                    </div>
                </div>
                {/*<div className="option">
                    <div className="icon">📞</div>
                    <div className="info">
                        <p>Telefone:</p> 
                        <a href="tel:+55123456789">(12) 3456-789</a>
                    </div>
                </div>*/}

                <div className="option">
                    <div className="icon">📱</div>
                    <div className="info">
                        <p>WhatsApp:</p>
                        <a href={data.footer.contatos.whatsappLink}>{data.footer.contatos.celular1}</a>
                    </div>
                </div>
                <div className="option">
                    <div className="icon">📝</div>
                    <div className="info">
                        <p>Formulário de Contato:</p>
                        <Link to="/contato">Contato</Link>
                    </div> 
                </div>
            </div>
        </Mapastyle>
    );
}
