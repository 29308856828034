 


export const data = {

    textoBanner:{
        titulo:'Impulsione seu negócio online',
        texto:'Crie landing pages profissionais e aumente suas conversões',
        titulo2:'Destaque-se na web',
        texto2:'Designs exclusivos para o seu público-alvo',
        

    },

    servicos:{
        nomeDaSessao: 'Nossos Serviços',
        titulo1: 'Design Personalizado',
        texto1: 'Nossas landing pages são meticulosamente criadas para representar fielmente a identidade visual da sua marca, proporcionando uma experiência única e memorável para os visitantes. Com designs exclusivos e totalmente personalizados, cada página é desenvolvida com atenção aos detalhes, destacando os valores e a essência da sua marca.',
        subtexto1:'Nossos designs são elaborados sob medida, refletindo a personalidade e os objetivos da sua marca de forma única e cativante.',

        titulo2: 'Otimização de Conversão',
        texto2: 'Nós empregamos técnicas avançadas de otimização para impulsionar a taxa de conversão das suas páginas, convertendo visitantes em clientes de forma eficaz e consistente. Através de estratégias refinadas, garantimos resultados tangíveis, aumentando suas vendas e gerando leads qualificados.',
        subtexto2:'Realizamos análises detalhadas das métricas relevantes para identificar oportunidades de melhorias contínuas, garantindo que suas páginas estejam sempre otimizadas para o máximo desempenho.',
        subtexto3:'Análise de métricas para melhorias contínuas',

        titulo3: 'Responsividade Garantida',
        texto3: 'Nossas landing pages são totalmente responsivas, adaptando-se a qualquer dispositivo, para que você não perca nenhum cliente.',
        subtexto4:'Compatibilidade com dispositivos móveis',
        subtexto5:'Experiência consistente em todos os dispositivos',
        subtexto6:'Testes de usabilidade',

        subtexto7:'Dê um passo à frente da concorrência',
        subtexto8:'Com landing pages de alta qualidade',
    },
    bloco3:{
        titulo: 'Por que escolher nossos serviços?',
       
        texto1: 'Experiência e Profissionalismo',
        paragrafo1: 'Nossa equipe possui experiência comprovada na criação de landing pages que geram resultados positivos para os clientes.',

        texto2: 'Atendimento Personalizado',
        paragrafo2: 'Entendemos as necessidades únicas de cada cliente e trabalhamos para superar suas expectativas com soluções sob medida.',

        texto3: 'Suporte Contínuo',
        paragrafo3: 'Estamos sempre disponíveis para oferecer suporte técnico e orientações para garantir o sucesso contínuo das suas campanhas online.',
    },
    
    bloco4:{
 
 
 
 
        textobanner:'Contribua para causas importantes',
        titulo:'Faça sua Doação',

        texto1:'Ajude-nos a fazer a diferença na vida de muitas pessoas.',
        texto2:'Toda doação conta e faz um grande impacto.',
        texto3:'Escolha uma das opções abaixo para contribuir:',

        botaoContribuir:'Contribuir Agora',

        texto4:'O que fazemos com as doações?',
        texto5:'Saiba como suas doações estão ajudando a comunidade.',
      },

    qrcode:{
        imagemqrcode:'qrcode'
    },
    

    footer:{
        contatos:{
            Contatos:'Contatos',
            telefone:' ',
            linkTelefone:' ',
            whatsapp:'WhatsApp:',
            celular1:'(11)99309-3681',
            celular2:' ',
              linkedin:'LinkedIn',
            linkedinLink:'https://www.linkedin.com/in/josuel-mroczko-677b36183/',
            whatsappLink:'https://wa.me/5511993093681?text=Em+breve+entrarei+em+contato',
            email:'systemloquaz@hotmail.com',

        },

      
 
        Sociais:{
            redesSociais:'Redes Sociais',
            linkedin:'LinkedIn',
            linkedinLink:'https://www.linkedin.com/in/josuel-mroczko-677b36183/',
            facebook:' ',
            Instagram:' ',
            InstagramLink:' '
        },

        politicas:{
            Politicas:'Políticas',
            politicasDeCompra:'Políticas de Compra',
            duvidas:'Dúvidas Frequentes',

        },
    }


}
