import styled from "styled-components";

export const ServicosStyle = styled.div`
  padding: 4% 5%;
  text-align: center;
  background-color: #f9f9f9; /* Fundo suave para destacar os cartões */

  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
    font-family: 'Roboto', sans-serif;
  }

  .container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0 auto;
    max-width: 1200px;
  }

  .card {
    width: 350px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    padding: 20px;
    color: #333;
    border: 1px solid #ddd;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    overflow: hidden;
    position: relative;
    cursor: default; /* Remove o cursor pointer já que não há interação */
  }

  .card:hover {
    transform: scale(1.03);
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.3);
  }

  .header {
    margin-bottom: 15px;
    border-bottom: 2px solid #eee;
    padding-bottom: 10px;
    font-weight: bold;
  }

  .content p {
    margin: 10px 0;
    line-height: 1.5;
  }

  @media screen and (max-width: 600px) {
    .card {
      width: 90%;
    }
    p {
      font-size: 14px;
    }
  }
`;
