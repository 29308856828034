import styled from 'styled-components';
import Cores from '../../utils/cores/cores';

export const FooterContainer = styled.footer`
  background-color: ${Cores.branco};
  padding: 40px 20px;
  color: ${Cores.preto};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.1);

  .footer-content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }

  h3 {
    color: ${Cores.preto};
    margin-bottom: 15px;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }

  li {
    margin: 10px 0;
  }

  a {
    color: ${Cores.preto};
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: color 0.3s ease, transform 0.3s ease;

    p {
      margin: 0;
      font-size: 1rem;
      font-weight: 300;
    }

    &:hover {
      color: ${Cores.amareloClaro};
      transform: translateY(-3px);
    }

    svg {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const Direitos = styled.div`
  margin-top: 20px;
  font-size: 0.9rem;
  color: ${Cores.preto};

  .direitos {
    margin: 0;
    text-align: center;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
