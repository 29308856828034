import styled from 'styled-components';

export const StyledME = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  h1 {
    color: red;

    text-align: center;
  }
`;
