// Altere os caminhos das imagens conforme necessário
 
import capa1 from '../imagem/pascoa.jpg'
import capa2 from '../imagem/blog2.png'
import capa3 from '../imagem/capaBlog3.png'
 

const produtosData = [ 
  {
    id: 1,
    nome: 'Jurídico',
    imagem: capa1,
    preco:120,
    link: "/nova1"
  },
  {
    id: 2,
    nome: 'Contábeis',
    imagem: capa2,
    preco:120,
    link: "/nova2"
  },
  {
    id: 3,
    nome: 'Comercios',
    imagem: capa3,
    preco:120,
    link: "/nova3"
  },
 
];

export default produtosData;
