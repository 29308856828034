import React, { useEffect } from 'react';
import { Container } from '../styles';
import Home from '../../home';
import Footer from '../../footer/footer';
 import imagem from './capaBlog3.png'
 import img2 from './capa3.png'

const Blog3 = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])


  return (
    <div><Home/>
   
   <Container> 
      
      <h2>Comercios</h2>
      <p>Modelos contabeis</p>
      <div className='objetos'>

         <div className='produto'>
          <a href="https://comercio-three.vercel.app/"  >
            <img src={imagem} alt="Blog" loading="lazy"/> 
            </a>
            <p>Modelo 1</p>
            </div>

            <div className='produto'>
          <a href="https://comercio2.vercel.app/"  >
            <img src={img2} alt="Blog" loading="lazy"/> 
            </a>
            <p>Modelo 2</p>
            </div>
        
        
 
      </div>
     
      
    </Container>
   <Footer/>
    </div>
  );
}

export default Blog3;
