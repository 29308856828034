import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 180px;
  background: linear-gradient(to bottom, #f4f4f4, #ffffff);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  text-align: center;

  h2 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 30px;
  }

  .objetos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .produto {
    max-width: 350px;
    width: 100%;
    text-align: center;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
    }

    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      border-radius: 15px;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }
    }

    p {
      font-size: 1.1em;
      color: #333;
      margin-top: 10px;
    }
  }
`;
