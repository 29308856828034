import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { Pagination, Navigation, Autoplay, EffectFade } from 'swiper/modules';
import StyleDiv from './style';
import { data } from '../../adicionarProdutos/dataTextos';
import { Helmet } from 'react-helmet';

export default function Banner({ banner1, banner2 }) {
  return (
    <StyleDiv>
   <Swiper
  modules={[Pagination, Navigation, Autoplay, EffectFade]}
  pagination={{ clickable: true }}
  navigation
  effect="coverflow" // Alterado para efeito fade para melhor desempenho
  speed={1000} // Reduzido para 1 segundo
  autoplay={{ delay: 5000 }} // Aumentado o delay para 5 segundos
  loop
>
  <SwiperSlide>
    <Helmet>
      <meta name="description" content={data.textoBanner.texto} />
    </Helmet>
    <div className="text-container">
      <h1>{data.textoBanner.titulo}</h1>
      <p>{data.textoBanner.texto}</p>
    </div>
    <div className="image-container">
      <img src={banner1} alt="banner 1" />
    </div>
  </SwiperSlide>
  <SwiperSlide>
    <div className="text-container">
      <h1>{data.textoBanner.titulo2}</h1>
      <p>{data.textoBanner.texto2}</p>
    </div>
    <div className="image-container">
      <img src={banner2} alt="banner 2"   />
    </div>
  </SwiperSlide>
</Swiper>

    </StyleDiv>
  );
}
