import React, { useEffect } from 'react';
import { Container } from '../styles';
import Home from '../../home';
import Footer from '../../footer/footer';
import imgContabil from './contabilidade.png'
 

const Blog2 = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  return (
    <div><Home/>
   
   <Container> 
      
      <h2>Contabilidade</h2>
      <p>Modelos contabeis</p>
      <div className='objetos'>

         <div className='produto'>
          <a href="https://contabil-five.vercel.app/"  >
            <img src={imgContabil} alt="Blog" loading="lazy"/> 
            </a>
            <p>Modelo 1</p>
            </div>
        
        
 
      </div>
     
      
    </Container>
   <Footer/>
    </div>
  );
}

export default Blog2;
