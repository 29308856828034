import { data } from "../../adicionarProdutos/dataTextos";
import { ServicosStyle } from "./servicosStyle";

export default function Servicos() {
    return (
        <ServicosStyle>
            <h2>{data.servicos.nomeDaSessao}</h2>
            <div className="container">
                {[1, 2, 3].map(id => (
                    <div key={id} className="card">
                        <div className="header">
                            <h4>{data.servicos[`titulo${id}`]}</h4>
                        </div>
                        <div className="content">
                            <p>{data.servicos[`texto${id}`]}</p>
                            <p>{data.servicos[`subtexto${id}`]}</p>
                            {id === 3 && <p>{data.servicos.subtexto7}</p>}
                        </div>
                    </div>
                ))}
            </div>
        </ServicosStyle>
    );
}
