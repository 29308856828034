import React, { useEffect } from 'react';
import { Container } from '.././styles';
import blogImage from './blog.png';  
import blogImage2 from './blog2.png'; 
import blogImage3 from './blog3.png';
import blogImage4 from './blog4.png';
 
 
import Home from './../../home/index';
import Footer from './../../footer/footer';

const Blog1 = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <Home/>
 
    <Container> 
      
      <h2>Juridico</h2>
      <p>Modelos Juridicos</p>
      <div className='objetos'>

         <div className='produto'>
          <a href="https://advogado4.vercel.app/"  >
            <img src={blogImage} alt="Blog" loading="lazy"/> 
            </a>
            <p>Modelo 1</p>
            </div>

         
            <div className='produto'>
          <a href="https://techjuris.vercel.app/"  >
            <img src={blogImage2} alt="Blog" loading="lazy"/> 
            </a>
            <p>Modelo 2</p>
            </div>

         
            <div className='produto'>
          <a href="https://advogado3.vercel.app/"  >
            <img src={blogImage3} alt="Blog" loading="lazy"/> 
            </a>
            <p>Modelo 3</p>
            </div>

         
            <div className='produto'>
          <a href="https://adevogado2.vercel.app/"  >
            <img src={blogImage4} alt="Blog" loading="lazy"/> 
            </a>
            <p>Modelo 4</p>
            </div>

        {/*    <div className='produto'>
          <a href="https://advogados-one-main.vercel.app/" target='blank'>
            <img src={blogImage5} alt="Blog" /> 
            </a>
            <p>Modelo 5</p>
            </div>
         */}
      </div>
     
      
    </Container>
    <Footer/>
    </>
  );
}

export default Blog1;
