import styled from "styled-components";

export const StyleWhatsap = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  img {
    width: 70px;
    transition: 200ms;
  }

  img:hover {
    width: 80px;
  }

  @media (max-width: 400px) {
    img {
    width: 50px;
    transition: 200ms;
  }

  img:hover {
    width: 70px;
  }
   }
`;
