import styled from "styled-components";

export const Mapastyle = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
      
    
    h2 {
        margin-bottom: 30px;
        font-size: 2.5rem;
        text-align: center;
        color: #333;
    }

    p {
        font-size: 1.2rem;
        text-align: center;
        color: #666;
        margin-bottom: 40px;
        width: 80%;
       
    }

    .contact-options {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
    }

    .option {
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.1);
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
        text-align: center;
      
        width: 300px;
        max-width: 100%;
    }
    .option a{
        position: relative;
        left: 10%;
     }

    .option:hover {
        transform: translateY(-5px);
    }

    .icon {
        font-size: 2rem;
        margin-right: 20px;
    }

    .info {
        flex: 1;
        text-align: left;
    }

    .info p {
        font-size: 1.1rem;
        margin-bottom: 5px;
        color: #555;
     
    }

    
    .info a {
        font-size: 1.1rem;
        text-decoration: none;
        color: #007bff;
        transition: color 0.3s ease;
   
        
    }

    .info a:hover {
        color: #0056b3;
    }
`;
