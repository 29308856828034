import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homeeapp from './pages/home/index.jsx';
import Entrar from './pages/entrar/index.jsx';
import { ImageProvider } from '../src/imagemProvider/imagemProvider.jsx';
import Sobre from './pages/sobre/sobre.jsx';
import './App.css';
import { Helmet } from 'react-helmet';
import Blog1 from './componentes/blog/blog1/blog1.jsx';
import Blog2 from './componentes/blog/blog2/blog2.jsx';
import Blog3 from './componentes/blog/blog3/blog3.jsx';
import Blog4 from './componentes/blog/blog4/blog4.jsx';
import ContatoConteudo from './pages/contatos/contatoConteudo.jsx';
import LoadingScreen from './LoadingScreen.jsx'; // Importe o componente de carregamento

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulação de um tempo de carregamento
    setTimeout(() => {
      setLoading(false);
    }, 5000); // 
  }, []);

  return (
    <ImageProvider>
      <Helmet>
        <meta name="description" content="bit sorcerers team" />
        <meta name="description" content="Sites para advogados" />
        <meta name="description" content="System Loquaz" />
      </Helmet>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Router>
          <Routes>
            <Route path="/" element={<Homeeapp />} />
            <Route path="/entrar" element={<Entrar />} />
            <Route path="/contato" element={<ContatoConteudo />} />
            <Route path="/sobre" element={<Sobre />} />
            <Route path="/nova1" element={<Blog1 />} />
            <Route path="/nova2" element={<Blog2 />} />
            <Route path="/nova3" element={<Blog3 />} />
            <Route path="/nova4" element={<Blog4 />} />
          </Routes>
        </Router>
      )}
    </ImageProvider>
  );
}

export default App;
