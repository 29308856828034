import styled from 'styled-components';

export const StyleProdutos = styled.div`
  padding: 2% 5%;
  background-color: #f4f4f4; /* Fundo claro para destacar os produtos */
  
  ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Espaço entre os itens */
  }

  li {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    width: 300px;
    max-width: 100%;
  }

  li:hover {
    transform: translateY(-10px);
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.3);
  }

  .produto-info {
    text-align: center;
    padding: 15px;
  }

  .imagem-produto {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 2px solid #eee;
    transition: transform 0.3s ease;
  }

  .imagem-produto:hover {
    transform: scale(1.1);
  }

  p {
    font-size: 1.2rem;
    margin: 10px 0;
    color: #333;
    font-weight: bold;
  }

  span {
    display: block;
    font-size: 1rem;
    color: #666;
    font-weight: 600;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;
