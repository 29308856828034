import { data } from '../../adicionarProdutos/dataTextos';
import { Direitos, FooterContainer } from './style';
import { FaPhone, FaLinkedin, FaEnvelope } from 'react-icons/fa'; // Ícones

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <div className="footer-content">
        <ul>
          <h3>{data.footer.contatos.Contatos}</h3>
         
          <li>
          <a href="tel:+55 993093681" rel="noopener noreferrer">
              <FaPhone /> <p>{data.footer.contatos.celular1}</p>
            </a>
          </li>
          <li>
            <a href={data.footer.Sociais.linkedinLink} rel="noopener noreferrer">
              <FaLinkedin /> <p>{data.footer.Sociais.linkedin}</p>
            </a>
          </li>
          <li>
            <a href="mailto:systemloquaz@hotmail.com">
              <FaEnvelope /> <p>{data.footer.contatos.email}</p>
            </a>
          </li>
        </ul>
      </div>
      <Direitos>
        <p className="direitos">
          Este site é protegido pelo reCAPTCHA e a Política de Privacidade e os
          Termos de Serviço do Google se aplicam. &copy; {currentYear}
        </p>
      </Direitos>
    </FooterContainer>
  );
};

export default Footer;
