import { StyledDiv } from './style';
 

export default function SobreConteudo() {
 

  return (
    <StyledDiv>
      <h1>SOBRE</h1>
      <p>Bem-vindo ao System Loquaz, sua plataforma especializada na criação e personalização de websites para profissionais de diversas áreas. Sabemos que, hoje em dia, ter uma presença online forte é essencial para o sucesso de qualquer negócio ou prática profissional. É por isso que nos dedicamos a fornecer soluções digitais de alta qualidade, especialmente projetadas para atender às necessidades únicas de diferentes setores.

Nosso compromisso é oferecer uma presença digital profissional, atraente e funcional para profissionais de diversas áreas. Com nossa equipe de especialistas em design e desenvolvimento web, trabalhamos arduamente para criar websites que não apenas impressionam visualmente, mas também fornecem uma experiência de usuário excepcional.

Entendemos as nuances e requisitos específicos de diversos mercados. Cada site que desenvolvemos é cuidadosamente planejado e personalizado para refletir a identidade única de cada negócio ou prática profissional, transmitindo confiança, credibilidade e profissionalismo aos visitantes.

Além disso, priorizamos a usabilidade e a acessibilidade em todos os nossos projetos. Nossos websites são otimizados para dispositivos móveis e são construídos com as mais recentes práticas de SEO (Search Engine Optimization), garantindo que sua empresa seja facilmente encontrada pelos clientes em potencial.

No System Loquaz, não apenas criamos websites, mas também estabelecemos parcerias duradouras com nossos clientes. Estamos comprometidos em oferecer suporte contínuo e assistência técnica para garantir que seu site esteja sempre atualizado e funcionando perfeitamente.

Se você é um profissional que busca se destacar online e atrair mais clientes, estamos aqui para ajudar. Entre em contato conosco hoje mesmo para saber mais sobre como podemos impulsionar a presença digital do seu negócio ou prática profissional.

Junte-se a nós no System Loquaz e dê o próximo passo rumo ao sucesso online!</p>
       
    </StyledDiv>
  );
}
