import styled from "styled-components";

export const ValoresStyle = styled.div`
  position: relative;
  background: linear-gradient(135deg, #2c3e50 0%, #4ca1af 100%);
  padding: 40px 20px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);

  h2 {
    text-align: center;
    color: #fff;
    font-size: 2em;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
    gap: 20px;
    border-radius: 10px;
  }

  li {
    text-align: center;
    flex: 1;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    }
  }

  strong {
    font-weight: bold;
    font-size: 1.4em;
    color: #f8f8f8;
    margin-bottom: 10px;
  }

  p {
    color: #d1e8ff;
    margin-bottom: 15px;
    font-size: 1.1em;
  }

  span {
    font-size: 1.6em;
    color: #ffd700;
    font-weight: bold;
  }

  @media (max-width: 580px) {
    ul {
      flex-wrap: wrap;
    }

    li {
      margin-bottom: 15px;
    }
  }
`;
